.app__connect {
    background-color: var(--color-bg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    border-radius: 25px;

    div, a {
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        transition: 0.5s ease;

        svg {
            font-size: 4rem;
            transition: 0.5s ease;
        }

        h1 {
            transition: 0.5s ease;
            font-family: var(--font-family);
            font-size: 1.5rem;
            font-weight: 400;
            text-transform: capitalize;
            color: var(--color-primary);
            text-align: center;
            line-height: 110%;
        }
    }
}

.app__connect-appointment {
    height: 100%;
    width: 100%;
    &:hover {
        background-color: var(--color-green);
        transition: 0.5s ease;
        
        svg {
            color:var(--color-primary);
        }

        h1 {
            color: var(--color-primary);
        }
    }
}

.app__connect-email {
    &:hover {
        background-color:  #DE4032;
        transition: 0.5s ease;
        
        svg {
            color:var(--color-primary);
        }

        h1 {
            color: var(--color-primary);
        }
    }
    
}

.app__connect-message {
    &:hover {
        background-color:  #037DF2;
        transition: 0.5s ease;
        
        svg {
            color: var(--color-primary);
        }

        h1 {
            color: var(--color-primary);
        }
    }
    
}

@media screen and (max-width: 1200px) {
    .app__connect {
        height: 50%;
        width: 80%;
        gap: 1rem;
        padding: 2rem;

        div, a {
            height: 70%;
            // border-radius: 25px;
            // transition: 0.5s ease;

            svg {
                font-size: 3rem;
            }

            h1 {
                font-size: 1.2rem;
            }
        }
    }
}


@media screen and (max-width: 960px) {
    .app__connect {
        width: 90%;

        div, a {

            svg {
                font-size: 2.5rem;
            }

            h1 {
                font-size: 1rem;
            }
        }
    }
}


@media screen and (max-width: 960px) {
    .app__connect {
        flex-direction: column;
    }
}


@media screen and (max-width: 530px) {
    .app__connect {
        width: 70%;
        padding: 1rem;
        background: none;
        gap: 2rem;

        & div {
            background-color: var(--color-bg);
        }
    }
}