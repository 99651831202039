.app__navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    gap: 5rem;
    padding: 4rem 0;
    position: sticky;
    top: 0;
    // overflow-y: hidden;
}

.app__navbar-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
        color: var(--color-primary);
        font-size: 3rem;
        font-family: var(--font-title);
        font-weight: 400;
        line-height: 90%;
    }

    h2 {
        color: var(--color-primary);
        font-size: 1.5rem;
        font-family: var(--font-family);
        font-weight: 400;
        line-height: 110%;
    }

    p {
        color: var(--color-secondary);
        font-size: 1.25rem;
        font-family: var(--font-family);
        margin: 0;

        span {
            font-family: var(--font-title);
        }
    }
}

.app__navbar-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.app__navbar-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    a {
        color: var(--secondary, #8498BD);
        font-size: 1.09375rem;
        font-family: var(--font-family);
    }

}

.app__navbar-contacts_socials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
        font-size: 2rem;
        color: var(--color-secondary);
        transition: 0.5s ease;
        cursor: pointer;
        
        &:hover {
            color: var(--color-primary);
        }
    }
}

.app__navbar > svg {
    display: none;
}

.app__navbar-collapsed {
    display: none;
}

#nav_close {
    display: none;
}

@media screen and (max-width: 1000px) {
    .app__navbar {
        z-index: 100;
        position: fixed;
        left: 0;
        padding: 2rem;
        transition: 0.3s ease;
        background: rgb(15, 23, 42, 0.85);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    }

    .app__navbar-header {
        h1 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.2rem;
        }

        p {
            font-size: 1rem;
        }
    }

    .app__navbar-links {
        a {
            font-size: 1rem;
        }
    }

    .app__navbar-contacts {
        button {
            padding: 0.625rem 2rem;
        }
    }

    #nav_close {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        &:hover {
            color: var(--color-green);
        }
    }

    .closed {
        opacity: 0 !important;
    }

    .app__navbar-collapsed {
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        width: 25px;
        top: 0;
        height: 100vh;
        background: rgb(15, 23, 42, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        opacity: 1;
        transition: opacity 2s ease;

        & > svg {
            top: 0;
            margin-top: 2rem;
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-height:900px) {
    // .app__navbar {
    //     gap: 3rem;
    //     padding: 4rem 0;
    // }
    
    .app__navbar-header {
        gap: 0.75rem;
    }
}

