@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Shanti&display=swap');
:root {
  --font-title: 'Black Ops One', cursive;
  --font-family: 'Shanti', sans-serif;
  
  --color-bg: rgb(15, 23, 42);
  --color-primary: #E2E8F0;
  --color-secondary: rgb(132, 152, 189);
  --color-green: rgb(27, 188, 155);
  --color-blue: rgb(97, 219, 251);
  --color-bg-highlight: rgba(var(--color-bg), 0.02);
  --colorHr : rgb(39, 47, 56);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, p, div {
  color: var(--color-primary);
  font-family: (--font-family);
}

a {
  color: unset;
  text-decoration: none;
}

body {
  background-color: var(--color-bg);
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.hide-right {
  -webkit-animation: hide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: hide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.header-gradient {
	background: linear-gradient(-45deg, #1BBC9B95, #61DBFB98, #1BBC9BEE, #61DBFBEE);
	// background: linear-gradient(-45deg, #1ABC9C95, #6bc5f898, #1ABC9CEE, #6bc5f8EE);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: gradient 5s ease infinite;
	background-size: 400% 400%;
}	

@keyframes gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@-webkit-keyframes hide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes hide-right  {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}


@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}


@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
} 

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


.container-loading {
	background: linear-gradient(-45deg, rgb(15, 23, 42), rgb(132, 152, 189), rgb(15, 23, 42), rgb(132, 152, 189));
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
