.app__cover {
    background: url('../../assets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;

    h2 {
        color: rgba(255, 255, 255, 0.35);
        text-align: center;
        font-size: 1.75rem;
        font-family: var(--font-family);
        font-weight: 400;

        span {
            font-family: var(--font-title);
        }
    }
}

.app__cover-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        color: rgba(255, 255, 255, 0.70);
        text-align: center;
        font-size: 3rem;
        font-family: var(--font-title);
        font-weight: 400;
    }

    p {
        color: rgba(255, 255, 255, 0.50);
        text-align: center;
        font-size: 1.25rem;
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }
    
}

.app__cover-link {
    position: absolute;
    bottom: 0;
    padding: 2rem;
    a {
        color: rgba(226, 232, 240, 0.50);
        font-size: 1rem;
        font-family: Shanti;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        display: flex;
        flex-direction: column;
        align-items: center;
        

        &:hover {
            color: rgba(226, 232, 240, 1);;
        }

        svg {
            font-size: 2rem;
        }
    }
}


@media screen and (max-width: 880px) {
    .app__cover {
        
        h2{
            font-size: 1.4rem;
        }

        h1 {
            font-size: 2.5rem;
        }

        p {
            font-size: 1rem;
        }
    }

}

@media screen and (max-width: 560px) {
    .app__cover {
        h1 {
            font-size: 2.2rem;
        }

    }
}