.accordionItem__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex:1;
    transition: flex 0.5s ease;
    &.collapsed {
        background-color: rgba(27, 188, 155, 0.25);
        flex:0;
    }
}

.accordionItem__container-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(27, 188, 155, 0.6);
    padding: 1rem 1.5rem;
    cursor: pointer;
    border-radius: 5px;
    svg {
        font-size: 1.5rem;
        color: var(--color-primary);
    }

    &:hover {
        background-color: rgba(27, 188, 155, 0.65);
    }
    &:active {
        background-color: rgba(27, 188, 155, 0.7);
    }

    &.collapsed {
        background-color: rgba(27, 188, 155, 0.25);

        &:hover {
            background-color: rgba(27, 188, 155, 0.45);
        }
        &:active {
            background-color: rgba(27, 188, 155, 0.6);
        }
    }
}

.accordionItem__container-content {
    width: 100%;
    padding: 1rem 1.5rem;
    background-color: rgba(255,255,255,0.05);
    // visibility: visible;
    // opacity: 1;
    height: 0;
    // height: auto;

    iframe {
        width: 100%;
        height: 100%;
        transition:  0.5s ease;
        object-fit: contain;
    }

    &.collapsed {
        // visibility: hidden;
        height: 0px;
        padding: 0 1.5rem;
        overflow: hidden;
        flex: 0;
        &>div {
            height: 0;
        }
    }

    &.expanded {
        min-height: 315px;
        height: 100%;
        transition: height 0.5s ease;
        flex: 1;
        
        &>div {
            height: 100%;
        }
    }
}