.app__project {
    display: flex;
    flex-direction: row;
    padding: 3rem;
    background-color: var(--color-bg);
    gap: 2rem;
    height: 100%;
    border-radius: 2rem;
    max-width: 1400px;

    position: relative;

    &>svg {
        position: absolute;
        right: 0;
        top: 0;
        margin: 2rem;
        font-size: 1.5rem;
        color: var(--color-secondary);
        cursor: pointer;

        &:hover {
            color: var(--color-primary);
        }   
    }
}

.app__project-info {
    flex:3;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.app__project-heading {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    div {
        display: flex;
        flex: row;
        gap: 1rem;

        img {
            width: 150px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            border: 1px solid rgba(255,255,255,0.3);
        }

        h1 {
            color: var(--color-green);
        }
    }
}

.app__project-tech {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h2 {
        color: var(--color-primary);
    }
    
    div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
    }
}

.app__project-other{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h2 {
        color: var(--color-primary);
    }

    div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
    }
}

.app__project-resources {
    flex:5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.app__project-resources_cover {
    width: 100%;
    max-height: 100px;
    border-radius: 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.app__project-resources_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    h2 {
        color: var(--color-primary);
    }

    div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
}


@media screen and (max-width:1200px) {
    .app__project-heading > div {
        flex-direction: column;
    }
}


@media screen and (max-width:960px) {
    .app__project {
        flex-direction: column;
    }
}


@media screen and (max-width: 530px) {
    .app__project {        
        border-radius: 0px;
        overflow-y: scroll;

        &>svg {
            position: fixed;
        }
    }

    .app__project-heading > div > img {
        width: 100%;
        height: auto;
    }
}


