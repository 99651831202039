.app__projects {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 4rem 0;
}

.app__projects-list {
    display: flex;
    flex-direction: column;
}