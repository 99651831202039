.tooltip__container {
    clip-path: circle(50% at center);
}
.tooltip__content {
    width: 500px;
    background: #192234;
    color: var(--color-primary);
    border-radius: 10px;
    border: 1px solid rgba($color: #fff, $alpha: 0.05);
    box-shadow: 5px 10px 15px 0px rgba(0, 0, 0, 0.25);
    transition: 0.3s ease;
    text-align: center;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;

    h1 {
        color: #FFF;
        font-size: 1.25rem;
        font-family: Shanti;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 0.5rem;
    }

    &>p {
        color: var(--color-secondary);
        font-size: 0.9rem;
        font-family: var(--font-family);
        line-height: 120%;
        margin-top: 0.5rem;
        text-decoration: underline;
    }
}