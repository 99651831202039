.tag__container {
    display: inline-block;
    padding: 2px 13px;
    background-color: rgba(27, 188, 155, 0.15);
    border-radius: 100px;
    transition: 0.3s ease;

    a, p {
        color: var(--color-green);
        font-size: 1rem;
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; 
        cursor: pointer;
    }

    &:hover{
        background-color: rgba(27, 188, 155, 0.25);
    }
    
    &:active{
        background-color: rgba(27, 188, 155, 0.35);
    }
}