.app__overlay {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    opacity: 0;
    visibility: hidden;
}

.app__overlay.active {
    opacity: 1;
    visibility: visible;
}