/* PADDINGS AND MARGINS */
.section__padding {
    padding: 0 16rem;
}
.overlay__padding {
    padding: 4rem 16rem;
}

::-webkit-scrollbar {
    background-color: rgba(27, 188, 155, 0.1);
    transition: background-color  0.5s ease;
}
::-webkit-scrollbar-track {
    background-color: rgba(27, 188, 155, 0.05);
    transition: background-color  0.5s ease;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(27, 188, 155, 0.2);
    transition: background-color  0.5s ease;
}
::-webkit-scrollbar-track:hover {
    background-color: rgba(27, 188, 155, 0.1) !important;
    transition: background-color  0.5s ease;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(27, 188, 155, 0.5);
    transition: background-color  0.5s ease;
}


.app__divider {
    height: 100%;
    width: 2px;
    background-color: rgba(255,255,255,0.1);
}

/* CUSTOM TEXT */

.section__title {
    color: rgba(27, 188, 155, 0.8);
    font-size: 3rem;
    font-family: var(--font-title);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.9rem;
    text-transform: uppercase;
}

.section__heading {
    color: var(--color-primary);
    font-size: 1.5rem;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: 110%;
}

.section__subheading {
    color: var(--color-primary);
    font-size: 1.25rem;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: 110%;
}

.p__text {
    color: var(--color-secondary);
    font-size: 1.09375rem;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: 150%;
}

.p__subtext {
    color: var(--color-secondary);
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: 120%;
}


/* CUSTOM ELEMENTS */

input, textarea {
    display: flex;
    padding: 0.625rem;
    align-items: flex-start;
    border-radius: 0.3125rem;
    border: 1px solid rgba(132, 152, 189, 0.50);
    background: rgba(132, 152, 189, 0.02);
    outline: none;

    color: var(--color-primary);
    font-size: 1.09375rem;
    font-family: var(--font-family);
    line-height: 150%;
}

button {
    padding: 0.625rem 4.6875rem;
    border: 1px solid var(--color-green);

    color: var(--color-green);
    text-align: center;
    font-size: 1.09375rem;
    font-family: var(--font-family);

    border-radius: 10px;
    background: transparent;
    fill: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: rgba(27, 188, 155, 0.8); 
        color: #fff;
    }

    &:active {
        background-color: rgba(27, 188, 155, 1); 
        color: #fff;
    }
}


.app__content {
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 2rem;
}

.bg__container {
    background-image: url('./assets/bg-subtle.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index:-1;
    opacity: 0.2;
}

.app__content-left {
    flex: 4;
}

.app__content-right {
    flex: 5 ;
    display: flex;
    flex-direction: column;
    gap: 10rem;
}

.disable-scroll {
    overflow: hidden;
  }

/* MEDIA SCREEN */

@media screen and (max-width: 1700px) {
    .section__padding {
        padding: 0 10rem;
    }
    
    .overlay__padding {
        padding: 2rem 10rem;
    }
}

@media screen and (max-width: 1500px) {
    .section__padding {
        padding: 0 8rem;
    }

    .overlay__padding {
        padding: 2rem 6rem;
    }
}
@media screen and (max-width: 1300px) {
    .section__padding {
        padding: 0 5rem;
    }

    .overlay__padding {
        padding: 2rem 3rem;
    }
}

@media screen and (max-width: 1000px) {
    .app__content {
        flex-direction: column;
    }
    .section__padding {
        padding: 0 6rem;
    }

    .app__skills-container div{
        width: 80px;
        height: 80px;
    }

    .logo-mask-color {
        background-color: transparent !important;
    }

    .project__container-image img  {
        mix-blend-mode: normal;
    }

    .app__content-right {
        gap: 5rem;
    }

    .App {
        overflow-y: scroll;
        
    }
    ::-webkit-scrollbar{
        display: none;
    }

    .app__about {
        margin: 0;
    }
}

@media screen and (max-width: 850px) {
    .section__padding {
        padding: 0 4rem;
    }
    
    .section__title {
        font-size: 2.5rem;
        letter-spacing: 0.7rem;
    }
    .app__projects-list {
        gap: 1rem;
    }

}

@media screen and (max-width: 650px) {
    .section__padding {
        padding: 0 3rem;
    }

    .overlay__padding {
        padding: 2rem 2rem;
    }
}


@media screen and (max-width: 530px) {
    .overlay__padding {
        padding: 0;
    }
    .section__title {
        font-size: 2.2rem;
        letter-spacing: 0.3rem;
        text-align: center;
    }

    .section__heading {
        font-size: 1.2rem;
    }
    .app__skills-container div{
        width: 60px;
        height: 60px;
    }

    .exp__container {
        flex-direction: column;
    }

    .app__projects-list {
        gap: 2rem;
    }
    
    .app__content-right {
        gap: 1rem;
    }

    .p__text {
        font-size: 1rem;
    }
}


@media screen and (max-width: 390px) {
    .section__padding {
        padding: 0 2rem;
    }

    .app__skills {
        align-items: center;

        &>h2 {
            text-align: center;
        }
    }
    .app__skills-container {
        justify-content: center;
    }
    .app__skills-tools {
        align-items: center;
        div {
            justify-content: center;
        }
    } 

    .project__container {
        align-items: center;
    }

    .section__title {
        font-size: 2rem;
    }

}