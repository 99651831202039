.app__skills {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 0;
    &>h1 {
        line-height: 90%;
    }
}

.app__skills-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.app__skills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    transition: 0.3s ease;
}

.app__skills-tags {
    display: flex;
    flex-direction: row;

    &>div {
        flex: 1;
    }
}

.app__skills-tools, .app__skills-tools {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &>div{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0.25rem;
    }
}