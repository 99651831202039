.app__experience {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    background-color: var(--color-bg);
    padding: 4rem;
    border-radius: 2rem;
    height: 100%;
    position: relative;

    &>svg {
        position: absolute;
        right: 0;
        top: 0;
        margin: 2rem;
        font-size: 1.5rem;
        color: var(--color-secondary);
        cursor: pointer;

        &:hover {
            color: var(--color-primary);
        }
    }
}

.app__experience-timeline {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &>h2 {
        text-transform: uppercase;
        text-align: center;
    }
}

.app__experience-details {
    flex: 5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.app__experience-details_heading {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.app__experience-details_heading-text {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    img {
        height: 75px;
        object-fit: contain;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        h1 {
            line-height: 90%;
        }
        a:hover {
            text-decoration: underline;
        }

        p {
            color: #62718d;
            font-size: 0.75rem;
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
    }
}

.app__experience-details_content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    overflow-y: scroll;
}

.app__experience-details_roles {
    display: flex;
    flex-direction: column;

    h2 {
        color: var(--color-primary);
        line-height: 100%;
    }

    ul  {
        li {
            margin-left: 1rem;
            transition: 0.3s ease;
            &:hover {
                background-color: rgba(255,255,255,0.05);
                color: var(--color-primary);
                border-radius: 5px;
                padding: 0.25rem .5rem;
            }
        }
    }
    
}


.app__experience-details_accomplishments {
    display: flex;
    flex-direction: column;

    h2 {
        color: var(--color-primary);
        line-height: 100%;
    }
    ul > li {
        margin-left: 1rem;
        transition: 0.3s ease;
        &:hover {
            background-color: rgba(255,255,255,0.05);
            color: var(--color-primary);
            border-radius: 5px;
            padding: 0.25rem .5rem;
        }
    }
}

.app__experience-details_projects {
    display: flex;
    flex-direction: column;

    h2 {
        color: var(--color-primary);
        line-height: 100%;
    }
    ul > li {
        margin-left: 1rem;
        transition: 0.3s ease;
        &:hover {
            background-color: rgba(255,255,255,0.05);
            color: var(--color-primary);
            border-radius: 5px;
            padding: 0.25rem .5rem;
        }
    }
}

.app__experience-details_skills {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    h2 {
        color: var(--color-primary);
    }
    div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
    }
}

@media screen and (max-width:1200px) {
    .app__experience {
        padding: 4rem 2rem;
        gap: 1rem;
    }
    
}



@media screen and (max-width:960px) {
    .app__experience {
        &>div:nth-child(2) {
           display: none;
        }
        &>div:nth-child(1) {
           display: none;
        }
    }
}


@media screen and (max-width: 530px) {
    .app__experience {
        border-radius: 0px;
        overflow-y: scroll;
    }
    .app__experience-details_heading-text {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 1rem;
    }
}