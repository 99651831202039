.app__menulink {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    cursor: pointer;

    a {
        color: var(--color-secondary);
        font-size: 1.25rem;
        font-family: var(--font-family);
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.125rem;
        transition: 0.5s ease;
        text-transform: uppercase;

        &:hover {
            color: rgba(255, 255, 255, 0.85);
        }

        &:active {
            color: rgba(255, 255, 255, 0.85);
        }
    }
}

.app__menulink-active > a {
    color: #fff !important;
    transition: 0.5s ease;
}

.app__menulink-line_active {
    height: 2px;
    width: 4.69125rem;
    background-color: var(--color-secondary);
    transition: 0.5s ease;
}


.app__menulink-line {
    height: 2px;
    width: 2.1875rem;
    background-color: var(--color-secondary);
    transition: 0.5s ease;
    
}